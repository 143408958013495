import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { GuideComponent } from './guide/guide.component';
import { StreamComponent } from './stream/stream.component';

const routes: Routes = [
  {path:'stream', component: StreamComponent},
  {path:'guide', component: GuideComponent},
  {path:'', component: StreamComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
