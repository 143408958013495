import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {


  constructor() {}
  ngOnInit() {

    let nav = document.querySelector(".navigation");
    addEventListener("scroll", function(){
      if(window.scrollY == 0){
        nav?.classList.add("default-top");
      }else {
        if(document.querySelector(".default-top")){
          nav?.classList.remove("default-top");
        }
      }
    });

  }
  title = 'WIP';









}
