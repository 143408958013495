<!-- BebasNeue-Regular-bold 

Boitameu, 28 ans passionné de jeux vidéo RPG médiéval fantastique.

Ma plus grande passion, Minecraft, jeu sur lequel je passe le plus clair de mon temps depuis la Beta 1.7 pour de la construction en créatif ou en survie.

Je possède une chaîne Youtube basé sur Minecraft, tutoriels, timelapse, level design, modélisation 3D.

Depuis Juillet 2020, je me lance à fond dans l'aventure twitch avec des streams réguliers avec pour objectif le twitch partenaire, n'hésitez pas à me soutenir en partageant le livestream, en lâchant un petit follow et si vous en avez les moyens un sub/donation. Merci à vous.

Horaire du stream:
Lundi, Mardi, Jeudi, Vendredi, Samedi à partir de 11h30
-->

<div class="title">
    <h2>LE STREAM</h2>
</div>

<div class="grid">
    <div class="flex">
        <div class="photo">
            <img alt="" src="../../assets/icon/Boitameu_LOGO_Grand2.png">
        </div>
        <div class="description">
            <p>
                Boitameu, 28 ans passionné de jeux vidéo RPG médiéval fantastique.
                <br><br>
                Ma plus grande passion, Minecraft, jeu sur lequel je passe le plus clair de mon temps depuis la Beta 1.7 pour de la construction en créatif ou en survie.
                <br><br>  
                Depuis Juillet 2020, je me lance à fond dans l'aventure twitch avec des streams réguliers avec pour objectif le twitch partenaire, n'hésitez pas à me soutenir en partageant le livestream, en lâchant un petit follow et si vous en avez les moyens un sub/donation.
                <br><br>
                 Je possède aussi une chaîne Youtube qui est basée sur Minecraft, tutoriels, timelapse, level design, modélisation 3D.
                 <br><br>
                Merci à vous.
            </p>
        </div>
        <div class="media">
            <a href="https://discord.gg/msdr7S"><img src="../../assets/icon/Discord.svg" alt="Discord"></a>
            <a href="https://twitter.com/BoitameuMC"><img src="../../assets/icon/twitter.svg" alt="Twitter"></a>
            <a href="https://www.instagram.com/boitameu/"><img src="../../assets/icon/instagram.svg" alt="Instagram"></a>
        </div>
    </div>
    <div class="flex"></div>
</div>
