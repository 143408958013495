
<header id="Home">
  <div class="navigation default-top" >
    <input type="checkbox" id="nav-toggle" class="nav-toggle">
    <div class="icon">
      <label for="nav-toggle" class="nav-toggle-label">
        <span class="b1"></span>
        <span class="b2"></span>
        <span class="b3"></span>
      </label>
      <!-- ; Trouver moyen autre que js pr set top linkHome sans href  -->
      <a id="linkHome" ><img alt="LogoBoite" id="logoBoite"></a>
      <a href="twitch://stream/boitameu"><img src="../assets/icon/twitch.svg" alt="LogoTwitch"></a>
    </div>
    <nav>
      <ul>
        <li><a routerLink="stream"> LE STREAM</a></li>
        <li><a routerLink="guide"> LES GUIDES </a></li>
        <li><a routerLink="patreon"> PATREON </a></li>
        <li><a routerLink="portfolio"> PORTFOLIO </a></li>
        <li><a routerLink="stream"> amet </a></li>
        <li><a routerLink="stream"> consectetur </a></li>
      </ul>
    </nav> 
  </div>
  <div class="twitch" >
    <iframe 
      class="video"
      src="https://player.twitch.tv/?channel=boitameu&parent=www.boitameu.fr">
    </iframe>
    <iframe 
      class="chat"
      src="https://www.twitch.tv/embed/boitameu/chat?darkpopout&parent=www.boitameu.fr">
    </iframe>


  </div>
  <div class="drawing-both"></div> 
</header>


<router-outlet></router-outlet>

<footer>
  Footer
</footer>